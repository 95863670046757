import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { HomeWrapper } from './style'
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { BASE_SITE_API } from '../../../config/setting'
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from "axios";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import moment from 'moment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import XNav from "../common/xnav";

const vertical = 'top'
const horizontal = 'right'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const dateTimeMap = [1, 2, 5, 10, 20, 30, 40, 50, 59]

const logStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    overflow: "auto",
    height: "700px",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  
class Profile extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {anchorElNav: '', SnackbarOpen: false, message: '', name: '', description: '', command: '', url:'', prespectivesRows: "", openAdd: false, logModal: false, openEdit: false, editData: "", delData: "", id:"", sort:"", delopen:false, openSnackbar: false, tip:{status:"info", message:""}, spider:"", spider_id: "", logMsg: "", execution_cycle: ""}
    } 

    componentDidMount(){
        this.loadData()
        var authorization = sessionStorage.getItem("authorization")
        if(!authorization) {
            this.props.history.push('/admin/login')
        }
    }

    componentDidUpdate(){
        // this.loadData()
        var authorization = sessionStorage.getItem("authorization")
        if(!authorization) {
            this.props.history.push('/admin/login')
        }
    }
    loadData = () => {
        axios({
            method:"get",
            url:`${BASE_SITE_API}/crontab`,
        }).then(res=>{
            if(res) {
                this.setState({
                    prespectivesRows: res.data.data
                })
            }else{
                alert('error: login fail')
            }
            
        }).catch(err => {
            console.log(err)
        })

        axios({
            method:"get",
            url:`${BASE_SITE_API}/spider`,
        }).then(res=>{
            if(res) {
                this.setState({
                    spider: res.data.data
                })
            }else{
                alert('error: login fail')
            }
            
        }).catch(err => {
            console.log(err)
        })
    }

    handleOpenNavMenu =  (event) => {
        this.setState({
          anchorElNav: event.currentTarget
        })
    }

    handleOpenDoc =  (e) => {
        if(this.props.account){
            this.props.history.push('/renderer/'+ encodeURIComponent(e))
        }else{
            this.setState({
                SnackbarOpen: true,
                message: "Please click the wallet in the upper right corner to login"
            });
        }
    }

    handleCloseSnackbar = () => {
        this.setState({
            SnackbarOpen: false
        });
    }
    
    fileChangeHandlerImage = (e) => {
        this.setState({
            image: e.target.files[0]
        })
    }

    changeHandler = (e) => {
        if(e.target.name === "execution_cycle") {
            this.setState({
                [e.target.name]: Number(e.target.value)
            })
        }else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
  
    }

    handleClickAdd = () => {
        this.setState({
            openAdd:true
        })
    }

    handleCloseAdd = (e) => {
        this.setState({
            openAdd:false
        })
    }

    handleClickEdit = (row) => {
        this.setState({
            openEdit:true,
            name:row.name,
            description:row.description,
            spider_id:row.spider_id,
            comment:row.comment,
            execution_cycle:row.execution_cycle,
            id:row.id,
            editData: row
        })
    }
    
    handleCloseEdit = () => {
        this.setState({
            openEdit:false
        })
    }

    showMessage = (status, message) => {
        this.setState({
            openSnackbar: true,
            tip: {
                status: status,
                message: message
            }
        })
    }

    onSubmitHandler = () => {
        const { name, description, spider_id, comment, execution_cycle } = this.state

        if(name && description && spider_id && comment && execution_cycle) {
            const data = new FormData();
   
            data.append("name", name);
            data.append("description", description);
            data.append("spider_id", spider_id);
            data.append("comment", comment);
            data.append("execution_cycle", execution_cycle);
            axios({
                method:"POST",
                url:`${BASE_SITE_API}/crontab/`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'authorization': sessionStorage.getItem("authorization")
                },
                data
            }).then(res=>{
                if(res.data.code === 200){
                    this.setState({
                        openAdd: false
                    })
                    this.showMessage("success", "add success")
                    this.loadData()
                }else{
                    this.showMessage("error", "Add failed")
                }
            }).catch(err => {
                this.showMessage("error", err)
            })
        } else {
            this.showMessage("error", "Incomplete parameters")
        }
    }
    
    onSubmitHandlerUpdate = () => {
        const { name, description, spider_id, execution_cycle, id, comment } = this.state

        if(name && description && id && execution_cycle && comment && spider_id) {
            const data = new FormData();
            data.append("name", name);
            data.append("description", description);
            data.append("spider_id", spider_id);
            data.append("comment", comment);
            data.append("execution_cycle", execution_cycle);

            axios({
                method:"PUT",
                url:`${BASE_SITE_API}/crontab/${id}`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'authorization': sessionStorage.getItem("authorization")
                },
                data
            }).then(res=>{
                if(res.data.code === 200){
                    this.setState({
                        openEdit: false
                    })
                    this.showMessage("success", "update success")
                    this.loadData()
                }else{
                    this.showMessage("error", "update failed")
                }
            }).catch(err => {
                this.showMessage("error", err)
            })
        } else {
            this.showMessage("error", "Incomplete parameters")
        }
    }

    handleClickDelete = () => {
        const { delData } = this.state
        if(delData.id){
            axios({
                method:"delete",
                url:`${BASE_SITE_API}/crontab/${delData.id}`,
                headers: {
                    'authorization': sessionStorage.getItem("authorization")
                },
            }).then(res=>{
                if(res.data.code === 200){
                    this.handleCloseDel()
                    this.showMessage("success", "Delete success")
                    this.loadData()
                }else{
                    this.showMessage("error", "Delete failed")
                }
            }).catch(err => {
                this.showMessage("error", err)
            })
        }else{
            this.showMessage("error", "Incomplete parameters")
        }
    }

    handleClickOpenLog = (spider_id) => {
        if(spider_id){
            axios({
                method:"POST",
                url:`${BASE_SITE_API}/crontab/log/${spider_id}`,
                headers: {
                    'authorization': sessionStorage.getItem("authorization")
                },
            }).then(res=>{
                if(res.data.code === 200){
                    this.setState({
                        logModal:true,
                        logMsg: res.data.data
                    })
                }else{
                    this.showMessage("error", "Delete failed")
                }
          
            }).catch(err => {
                this.showMessage("error", err)
            })
        }else{
            this.showMessage("error", "Incomplete parameters")
        }
    };

    handleCloseLogModal = () => {
        this.setState({
            logModal:false
        })
    };

    handleCloseDel = () => {
        this.setState({
            delopen:false
        })
    };

    handleClickDelOpen = (row) => {
        this.setState({
            delopen:true,
            delData:row
        })
    }

    handleClickCheckSpiderStatus = (row) => {
        axios({
            method:"POST",
            url:`${BASE_SITE_API}/crontab/checkSpiderStatus`,
            headers: {
                'authorization': sessionStorage.getItem("authorization")
            },
        }).then(res=>{
            if(res.data.code === 200){
                this.showMessage("success", "success")
                this.loadData()
            }else{
                this.showMessage("error", "Delete failed")
            }
      
        }).catch(err => {
            this.showMessage("error", err)
        })
    }

    render() {
        // const { myNftAssets } = this.props
        const { prespectivesRows, openAdd, openEdit, editData, delopen, openSnackbar, tip, spider, spider_id, logModal, logMsg, execution_cycle} = this.state;
        return (
            <HomeWrapper>
                <Grid
                    className='cl-grid'
                >

                <XNav history={this.props.history}/>

                <Typography variant="h5" gutterBottom component="div">
                        Add Crontab
                </Typography>
                <Typography sx={{ marginBottom: "30px" }}  gutterBottom component="div">
                    <Button variant="contained" onClick={this.handleClickAdd}>ADD</Button>
                    <Button variant="contained" onClick={this.handleClickCheckSpiderStatus} sx={{ marginLeft: "10px" }}>Check spider status</Button>
                </Typography>
                <TableContainer component={Paper}>

                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                        <TableRow>
                            <TableCell align="center">ID</TableCell>
                            <TableCell align="center">name</TableCell>
                            <TableCell align="center">description</TableCell>
                            <TableCell align="center">comment name</TableCell>
                            <TableCell align="center">spider name</TableCell>
                            <TableCell align="center">execution cycle (Unit minute)</TableCell>
                            <TableCell align="center">spider status</TableCell>
                            <TableCell align="center">Create Date</TableCell>
                            <TableCell align="center">Update Date</TableCell>
                            <TableCell align="center">Option</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        { prespectivesRows? prespectivesRows.map((row, index) => (
                            <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell align="center">{row.id}</TableCell>
                            <TableCell align="left">{row.name}</TableCell>
                            <TableCell align="left">{row.description}</TableCell>
                            <TableCell align="center">{row.comment}</TableCell>
                            <TableCell align="center">{row.spider_name}</TableCell>
                            <TableCell align="center">{row.execution_cycle}</TableCell>
                            <TableCell align="center">{row.run_status === 0 ? <Typography gutterBottom component="div"  sx={{ color: "#1e9522", fontWeight:"bold" }}>RUN </Typography> : <Typography  gutterBottom component="div" sx={{ color: "#f71f0a", fontWeight:"bold" }}>WARNING </Typography> }</TableCell>
                            <TableCell align="center">{moment(row.create_time*1000).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                            <TableCell align="center">{moment(row.update_time*1000).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                            <TableCell align="center" sx={{ display: "flex" }}>
                                <Button variant="contained" onClick={() =>this.handleClickEdit(row)}>Edit</Button>
                                <Button variant="contained" onClick={() =>this.handleClickDelOpen(row)} sx={{ marginLeft: "10px" }}>Delete</Button>
                                <Button variant="contained" onClick={() =>this.handleClickOpenLog(row.spider_id)} sx={{ marginLeft: "10px" }}>log</Button>
                            </TableCell>
             
                            {/* <TableCell  sx={{ display: "flex" }}> */}
                   
                            {/* </TableCell> */}
                            </TableRow>
                        )):  
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                               <TableCell align="right">no data</TableCell>
          
                        </TableRow>
                        
                        }
                        </TableBody>
                    </Table>
                </TableContainer>
                </Grid>   
            <Modal
            open={openAdd}
            onClose={this.handleCloseAdd}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Add 
                </Typography>
                <form onSubmit={() => this.onSubmitHandler} method="post" className='cl-form' encType="multipart/form-data">
                    <TextField id="outlined-basic" label="name" fullWidth name="name" className='cl-text' onChange={this.changeHandler}  variant="outlined" sx={{
                        '& > :not(style)': { m: 1},
                    }}/>

                    <TextField
                        id="outlined-multiline-static"
                        label="description"
                        name="description" 
                        multiline
                        fullWidth
                        rows={6}
                        onChange={this.changeHandler}
                        className='cl-text'
                        sx={{
                            '& > :not(style)': { m: 1},
                        }}
                    />
                    <FormControl fullWidth sx={{
                          marginLeft: "8px",
                          marginBottom : "8px",
                        }}>
                        <InputLabel id="demo-simple-select-labela">Spider Id</InputLabel>
                        <Select
                            labelId="demo-simple-select-labela"
                            name="spider_id"
                            value={spider_id}
                            label="Spider Id"
                            onChange={this.changeHandler}
                        >
                        { spider? spider.map((row, index) => (

                               <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                        )):  
                               <MenuItem  value="">mo data</MenuItem>
                        }
                        </Select>
                    </FormControl>
                    <TextField id="outlined-basic" label="comment name" fullWidth name="comment" className='cl-text' onChange={this.changeHandler}  variant="outlined" sx={{
                        '& > :not(style)': { m: 1},
                    }}/>
                    <FormControl fullWidth sx={{
                          marginTop: "8px",
                          marginLeft: "8px",
                          marginBottom : "8px",
                    }}>
                        
                    <InputLabel id="demo-simple-select-label">execution cycle</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="execution_cycle"
                        // defaultValue={editData.spider_id}
                        value={execution_cycle}
                        label="execution cycle"
                        onChange={this.changeHandler}
                        >
                            { dateTimeMap.map((row) => (
                               <MenuItem key={row} value={row}>{row}</MenuItem>
                           ))}
                        </Select>
                    </FormControl>
                    <br/>
                    <Button sx={{marginTop:"30px"}} variant="contained" onClick={this.onSubmitHandler}>Submit</Button>
                    <br/>
                </form>
            </Box>
            </Modal>
            <Modal
            open={openEdit}
            onClose={this.handleCloseEdit}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                 Update
                </Typography>
                <form onSubmit={() => this.onSubmitHandler} method="post" className='cl-form' encType="multipart/form-data">
                    <TextField label="name" fullWidth name="name"  className='cl-text' defaultValue={editData.name}  onChange={this.changeHandler}  variant="outlined" sx={{
                        '& > :not(style)': { m: 1 },
                    }}/>
                    <TextField
                        id="outlined-multiline-static"
                        label="description"
                        name="description" 
                        multiline
                        fullWidth
                        rows={6}
                        defaultValue={editData.description}
                        onChange={this.changeHandler}
                        className='cl-text'
                        sx={{
                            '& > :not(style)': { m: 1 },
                        }}
                    />
                    <FormControl fullWidth sx={{
                          marginTop: "8px",
                          marginLeft: "8px",
                          marginBottom : "8px",
                    }}>
                        
                    <InputLabel id="demo-simple-select-label">Spider Id</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="spider_id"
                        // defaultValue={editData.spider_id}
                        value={spider_id}
                        label="Spider Id"
                        onChange={this.changeHandler}
                        >
                        { spider && spider.map((row, index) => (
                            <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                        ))}
                        </Select>
                    </FormControl>

                    <TextField  label="comment" name="comment" disabled fullWidth className='cl-text' onChange={this.changeHandler}  defaultValue={editData.comment}  variant="outlined" sx={{
                        '& > :not(style)': { m: 1 },
                    }}/>
                    
                    <FormControl fullWidth sx={{
                          marginTop: "8px",
                          marginLeft: "8px",
                          marginBottom : "8px",
                    }}>
                        
                    <InputLabel id="demo-simple-select-label">execution cycle</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="execution_cycle"
                        // defaultValue={editData.spider_id}
                        value={execution_cycle}
                        label="execution cycle"
                        onChange={this.changeHandler}
                        >
                            { dateTimeMap.map((row) => (
                               <MenuItem key={row} value={row}>{row}</MenuItem>
                           ))}
                        </Select>
                    </FormControl>
                    <br/>
                    <Button  sx={{marginTop:"30px"}}  variant="contained" onClick={this.onSubmitHandlerUpdate}>Submit</Button>
                    <br/>
                </form>
            </Box>
            </Modal>

            <Modal
            open={logModal}
            onClose={this.handleCloseLogModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={logStyle}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  <pre dangerouslySetInnerHTML={{__html: logMsg}}></pre>
                </Typography>
            </Box>
            </Modal>

            <Dialog
                open={delopen}
                onClose={this.handleCloseDel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">
             {"Tips"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Are you sure to delete this data?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={this.handleClickDelete}  sx={{color:"#1236ff"}}>confirm</Button>
            <Button onClick={this.handleCloseDel} autoFocus>
            cancel
            </Button>
            </DialogActions>
           </Dialog>
           <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={this.handleSnackbarClose} anchorOrigin={{ vertical, horizontal }}>
                <Alert onClose={this.handleSnackbarClose} severity={tip.status} sx={{ width: '100%' }}>
                     {tip.message}
                </Alert>
          </Snackbar>
            </HomeWrapper>
        )
    }
}

const mapState = (state) => ({
    opensea: state.getIn(['header', 'opensea']),
    chainId: state.getIn(['header', 'chainId']),
    account: state.getIn(['header', 'account']),
    myNftAssets: state.getIn(['header', 'myNftAssets']),
})


export default connect(mapState)(Profile);

