import React, { PureComponent } from 'react';

// import { actionCreators } from './store';
import { connect } from 'react-redux';
import { HomeContainer } from './style.js'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import ReactPlayer from 'react-player'

// import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
// import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/Inbox';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { BASE_SITE_API, COURSE_TOKEN_ABI, COURSE_TOKEN_ADDRESS } from "../../../config/setting";
import axios from "axios";
import Web3 from "web3";

const vertical = 'top'
const horizontal = 'right'

class Profile extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {courseVideoList: '', open: false, price: "", order: "", isOwner: false, SnackbarOpen: false, message: "", selectedIndex: "", video: "", videoCover: "https://blog.liquid.com/hubfs/WEB-3.0-2_220120.png"}
    } 
    
    async componentDidMount() {
            const { account, match, walletProvider } = this.props
            if(walletProvider && match.params.tokenId){
                var webObj = new Web3(walletProvider)
                var myContract = new webObj.eth.Contract(COURSE_TOKEN_ABI, COURSE_TOKEN_ADDRESS)
                await myContract.methods.balanceOf(account, match.params.tokenId).call({from:account}).then((res)=>{
                    var isTrue = false
                    if(parseInt(res) > 0) {
                        isTrue = true
                        this.setState({
                            balanceOf: isTrue
                        })
                        this.loadData()
                    }else{
                        this.setState({
                            balanceOf: isTrue
                        })
                        this.props.history.push('/course/buy/'+ encodeURIComponent(match.params.tokenId))
                    }
                }).catch(err => {
                    console.log("err", err)
                })
            //    await getTokenIdOwners(DOC_TOKEN_ADDRESS, match.params.tokenId, Web3.utils.toHex(chainId)).then((res)=>{
            //        var isTrue = false
            //          res.result.forEach((item) => {
            //                 if(item.owner_of.toLocaleUpperCase()=== account.toLocaleUpperCase()) {
            //                     isTrue = true
            //                 }
            //         });
            //         this.setState({
            //             balanceOf: isTrue
            //         })
    
            //         if(!isTrue) {
            //             this.props.history.push('/buy/'+ encodeURIComponent(match.params.tokenId))
            //         }else{
            //             this.setState({
            //                 pdfPath: `./doc/${match.params.tokenId}.xyz`
            //             })
            //         // const result = require(response.path)
            //         }
    
            //     }).catch(err=>{
            //         console.log(err)
            //     })
            }
        
      
    }

    loadData = () => {
        const { match } = this.props
        axios({
            method:"get",
            url:`${BASE_SITE_API}/course/courseVideoList/${Number(match.params.tokenId)}`,
        }).then(res=>{
            if(res.data.code === 200){
                if(res.data.data.length>0){
                    this.setState({
                        courseVideoList: res.data.data,
                        video: BASE_SITE_API+"/course/video/"+res.data.data[0]["id"]+"/"+sessionStorage.getItem("signatrue"),
                        videoCover: res.data.data[0]["cover"]
                    })
                } 
            }else{
                this.setState({
                    courseVideoList: []
                })
            }
            
        }).catch(err => {
            console.log(err)
        })
    }

    handleCliockShowSellBox = () => {
         this.setState({
            open: true
          });
    }

    handleCliockBuyNow = async () => {
        //  const { account, match, walletProvider, chainId } = this.props
        //  if(account){
        //     var webObj = new Web3(walletProvider)
        //     var myContract = new webObj.eth.Contract(DOC_TOKEN_ABI, DOC_TOKEN_ADDRESS)
        //      var price = await Web3.utils.toWei(DOC_PRICE, 'ether')
        //     await myContract.methods.mint(account, match.params.tokenId, 1, []).send({from:account,value:(price*1)}).then(()=>{
        //         this.setState({
        //             SnackbarOpen: true,
        //             message: "success"
        //           })
        //           var _this = this
        //           this.props.upodateMyAssets(account, chainId)
        //           setTimeout(()=>{
        //             _this.props.history.push('/profile')
        //           }, 3000)
        //     }).catch(err=>{
        //         this.setState({
        //             SnackbarOpen: true,
        //             message: err.message
        //         });
        //     })
 
        //  }else{
        //     this.setState({
        //         SnackbarOpen: true,
        //         message: "Please click the wallet in the upper right corner to login"
        //     });
        //  }
    }


    handleClose = () => {
        this.setState({
          open: false
        });
    }

    handleCloseSnackbar = () => {
        this.setState({
            SnackbarOpen: false
        });
    }

    handleCnhange = e => {
        const data = e.target.value
        this.setState({
            price: data
        });
    }

    htmlDecode(content) {
        let e = document.createElement('div');
        e.innerHTML = content;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }

    handleListItemClick(event, index) {
      const { courseVideoList } = this.state
        this.setState({
            selectedIndex: index,
            video: BASE_SITE_API+"/course/video/"+courseVideoList[index]["id"]+"/"+sessionStorage.getItem("signatrue"),
            videoCover: courseVideoList[index]["cover"]
        });

    }

    handleClick(event, index) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    render() {
        const { open, price, SnackbarOpen, message, selectedIndex, video, videoCover, courseVideoList } = this.state
        
        return (
            <HomeContainer>
            <div role="presentation" onClick={this.handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                Test Course
                </Link>
                {/* <Typography color="text.primary"></Typography> */}
            </Breadcrumbs>
            </div>
    
         
                <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                justifyContent="center"
                sx={{ margin: `20px 4px 10px 4px` }}
                columns={{ xs: 4, sm: 8, md: 12 }}             
                  >
                    <Grid item  xs={8} sm={12} md={8} >
                        <Card >
                            <Typography sx={{ mixHeight: `556px`, display: "flex", justifyContent: "center", alignContent: "center" }}  component={'div'}  variant={'body2'}>
               
                            <ReactPlayer url={video} 
                             playing={true}
                             config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                             onContextMenu={e => e.preventDefault()}
                             controls
                             light={videoCover}
                            />
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={8} sm={12} md={4}>
                    <Card sx={{ height: `556px`, display: "flex", justifyContent: "center", alignContent: "center" }}>
                    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        <List component="nav" aria-label="main mailbox folders">
                            
                            { courseVideoList ? courseVideoList.map((row, index) => (
                            <ListItemButton key={index}
                            selected={selectedIndex === index}
                            onClick={(event) => this.handleListItemClick(event, index)}
                            >
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary={row.title} />
                            </ListItemButton>

                              )) : "" }
                       
                        </List>
                    </Box>
                    </Card>
                    </Grid>
                 
                </Grid>
                <Dialog
          
                    open={open}
                    onClose={this.handleClose}
                >
                    <DialogTitle>Price setting</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                       Please enter a fixed price.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="price"
                        label="fixed price"
                        type="name"
                        fullWidth
                        variant="standard"
                        value={price} 
                        onChange={this.handleCnhange}
                    />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} >Close</Button>
                        <Button onClick={this.handleCliockSell}>confirm</Button>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    autoHideDuration={6000} 
                    open={SnackbarOpen}
                    onClose={this.handleCloseSnackbar}
                    message={message}
                    key={vertical + horizontal}
                />
            </HomeContainer>
        )
    }

}

const mapState = (state) => ({
    walletProvider: state.getIn(['header', 'walletProvider']),
    chainId: state.getIn(['header', 'chainId']),
    account: state.getIn(['header', 'account']),
    myNftAssets: state.getIn(['header', 'myNftAssets']),
})

const mapDispatch = (dispatch) => ({
     async upodateMyAssets(accounts, chainId){
    //     var myAssets = await getAssets(accounts, DOC_TOKEN_ADDRESS, Web3.utils.toHex(chainId))
    //     myAssets.result.forEach(async (item, key) => {
    //         if(item.token_uri) {
    //            myAssets.result[key].metadata = await getMetadata(item.token_uri);
    //         }
    //    });
    //     dispatch(actionCreators.setMyNftAssets(myAssets.result));
     }
})
export default connect(mapState, mapDispatch)(Profile);