import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { HomeWrapper } from './style'
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { BASE_SITE_API } from '../../../config/setting'
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from "axios";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import moment from 'moment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import XNav from "../common/xnav";

const vertical = 'top'
const horizontal = 'right'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  
class Profile extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {anchorElNav: '', SnackbarOpen: false, message: '', name: '', description: '', apiurl: '', apisql:'', type: 1, prespectivesRows: "", openAdd: false, openEdit: false, editData: "", delData:"", id:"", openSnackbar: false, tip:{status:"info", message:""}, delopen: false, spider:"", spider_id: ""}
    } 

    componentDidMount(){
        this.loadData()
        var authorization = sessionStorage.getItem("authorization")
        if(!authorization) {
            this.props.history.push('/admin/login')
        }
    }

    componentDidUpdate(){
        // this.loadData()
        var authorization = sessionStorage.getItem("authorization")
        if(!authorization) {
            this.props.history.push('/admin/login')
        }
    }

    loadData = () => {
        axios({
            method:"get",
            url:`${BASE_SITE_API}/subgraph`,
        }).then(res=>{
            if(res) {
                this.setState({
                    prespectivesRows: res.data.data
                })
            }else{
                alert('error: login fail')
            }
            
        }).catch(err => {
            console.log(err)
        })

        axios({
            method:"get",
            url:`${BASE_SITE_API}/spider`,
        }).then(res=>{
            if(res) {
                this.setState({
                    spider: res.data.data
                })
            }else{
                alert('error: login fail')
            }
            
        }).catch(err => {
            console.log(err)
        })
    }

    handleOpenNavMenu =  (event) => {
        this.setState({
          anchorElNav: event.currentTarget
        })
    }

    handleOpenDoc =  (e) => {
        if(this.props.account){
            this.props.history.push('/renderer/'+ encodeURIComponent(e))
        }else{
            this.setState({
                SnackbarOpen: true,
                message: "Please click the wallet in the upper right corner to login"
            });
        }
    }

    handleCloseSnackbar = () => {
        this.setState({
            SnackbarOpen: false
        });
    }
    
    fileChangeHandlerImage = (e) => {
        this.setState({
            image: e.target.files[0]
        })
    }

    changeHandler = (e) => {
        if(e.target.name === "type"){
            this.setState({
                [e.target.name]: Number(e.target.value)
            })
        }else{
            this.setState({
                [e.target.name]: e.target.value
            })
        }

    }

    handleClickAdd = () => {
        this.setState({
            openAdd: true,
            type: 1,
        })
    }

    handleCloseAdd = (e) => {
        this.setState({
            openAdd:false
        })
    }

    handleClickEdit = (row) => {
        this.setState({
            openEdit:true,
            name:row.name,
            description:row.description,
            apiurl:row.apiurl,
            apisql:row.apisql,
            type: Number(row.type),
            spider_id:row.spider_id,
            id:row.id,
            editData: row
        })
    }

    
    
    handleCloseEdit = () => {
        this.setState({
            openEdit:false
        })
    }

    showMessage = (status, message) => {
        this.setState({
            openSnackbar: true,
            tip: {
                status: status,
                message: message
            }
        })
    }

    onSubmitHandler = () => {
        const { name, description, apiurl, apisql, type, spider_id } = this.state
        const data = new FormData();
        data.append("name", name);
        data.append("description", description);
        data.append("type", type);
        if(name && description && apiurl && apisql && type === 1) {
            data.append("apiurl", apiurl);
            data.append("apisql", apisql);
            axios({
                method:"POST",
                url:`${BASE_SITE_API}/subgraph`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'authorization': sessionStorage.getItem("authorization")
                },
                data
            }).then(res=>{
                if(res.data.code === 200){
                    this.setState({
                        openAdd: false
                    })
                    this.showMessage("success", "add success")
                    this.loadData()
                }else{
                    this.showMessage("error", "Add failed")
                }
            }).catch(err => {
                this.showMessage("error", err)
            })
        }else if(name && description && type === 2 && spider_id) {
            data.append("spider_id", spider_id);
            axios({
                method:"POST",
                url:`${BASE_SITE_API}/subgraph`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'authorization': sessionStorage.getItem("authorization")
                },
                data
            }).then(res=>{
                if(res.data.code === 200){
                    this.setState({
                        openAdd: false
                    })
                    this.showMessage("success", "add success")
                    this.loadData()
                }else{
                    this.showMessage("error", "Add failed")
                }
            }).catch(err => {
                this.showMessage("error", err)
            })
        } else {
            this.showMessage("error", "Incomplete parameters")
        }
    }
    
    onSubmitHandlerUpdate = () => {
        const { name, description, apiurl, apisql, id, type, spider_id } = this.state
        const data = new FormData();
        data.append("name", name);
        data.append("description", description);
        data.append("type", type);
        if(name && description && id && apiurl && apisql && type === 1) {
            data.append("apiurl", apiurl);
            data.append("apisql", apisql);
         
            axios({
                method:"PUT",
                url:`${BASE_SITE_API}/subgraph/${id}`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'authorization': sessionStorage.getItem("authorization")
                },
                data
            }).then(res=>{
                if(res.data.code === 200){
                    this.setState({
                        openEdit: false
                    })
                    this.showMessage("success", "update success")
                    this.loadData()
                }else{
                    this.showMessage("error", "update failed")
                }
            }).catch(err => {
                this.showMessage("error", err)
            })
        } else if(name && description && id && spider_id && type === 2) {
            data.append("spider_id", spider_id);


            axios({
                method:"PUT",
                url:`${BASE_SITE_API}/subgraph/${id}`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'authorization': sessionStorage.getItem("authorization")
                },
                data
            }).then(res=>{
                if(res.data.code === 200){
                    this.setState({
                        openEdit: false
                    })
                    this.showMessage("success", "update success")
                    this.loadData()
                }else{
                    this.showMessage("error", "update failed")
                }
            }).catch(err => {
                this.showMessage("error", err)
            })
        } else {
            this.showMessage("error", "Incomplete parameters")
        }
     
    }

    handleClickDelete = (row) => {
        const { delData } = this.state
        if(delData.id){
            axios({
                method:"delete",
                url:`${BASE_SITE_API}/subgraph/${delData.id}`,
                headers: {
                    'authorization': sessionStorage.getItem("authorization")
                },
            }).then(res=>{
                if(res.data.code === 200){
                    this.handleCloseDel()
                    this.showMessage("success", "Delete success")
                    this.loadData()
                }else{
                    this.showMessage("error", "Delete failed")
                }
            }).catch(err => {
                this.showMessage("error", err)
            })
        }else{
            this.showMessage("error", "Incomplete parameters")
        }
    }

    handleClickOpenApiLink = (row) => {
        var urlLink = BASE_SITE_API+"/api/"+row.apikey
        window.open(urlLink, "_blank")
    }

    handleCloseDel = () => {
        this.setState({
            delopen:false
        })
    };

    handleClickDelOpen = (row) => {
        this.setState({
            delopen:true,
            delData:row
        })
    }

    render() { 
        // const { myNftAssets } = this.props
        const { prespectivesRows, openAdd, openEdit, editData, delopen, openSnackbar, tip, type, spider, spider_id } = this.state;
        return (
            <HomeWrapper>
                <Grid
                    className='cl-grid'
                >

                <XNav history={this.props.history}/>

                <Typography variant="h5" gutterBottom component="div">
                   Subgraph Api
                </Typography>
                <TableContainer component={Paper}>
                <Button variant="contained" onClick={this.handleClickAdd}>ADD</Button>

                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                        <TableRow>
                            <TableCell align="center">ID</TableCell>
                            <TableCell align="center">name</TableCell>
                            <TableCell align="center">Description</TableCell>
                            <TableCell align="center">type</TableCell>
                            <TableCell align="center">Spider Name</TableCell>
                            {/* <TableCell align="center">apiurl</TableCell> */}
                            <TableCell align="center">apikey</TableCell>
                            <TableCell align="center">Create Date</TableCell>
                            <TableCell align="center">Option</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        { prespectivesRows? prespectivesRows.map((row, index) => (
                            <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell align="center">{row.id}</TableCell>
                            <TableCell align="center">{row.name}</TableCell>
                            <TableCell align="center">{row.description}</TableCell>
                            <TableCell align="center">{row.type===1?"Subgraph": "Spider"}</TableCell>
                            <TableCell align="center">{row.spider_name? row.spider_name: "/"}</TableCell>
                            {/* <TableCell align="center">
                              {row.apiurl ? row.apiurl : "/"}
                            </TableCell> */}
                            <TableCell align="center">
                              {row.apikey}
                            </TableCell>
                            <TableCell align="center">{moment(row.create_date*1000).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                            <TableCell align="center" sx={{ display: "flex",marginRight:"30px", width:"290px"}}>
                            <Button variant="contained" sx={{ marginRight:"10px"}} onClick={() =>this.handleClickEdit(row)}>Edit</Button>
                            <Button variant="contained" sx={{ marginRight:"10px"}} onClick={() =>this.handleClickDelOpen(row)}>Delete</Button>
                            <Button variant="contained" sx={{ marginRight:"10px"}} onClick={() =>this.handleClickOpenApiLink(row)}>Open Api </Button>
                            </TableCell>
                            </TableRow>
                        )):  
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                               <TableCell align="right">no data</TableCell>
          
                        </TableRow>
                        
                        }
                        </TableBody>
                    </Table>
                </TableContainer>
                </Grid>
            <Modal
            open={openAdd}
            onClose={this.handleCloseAdd}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                Add 
                </Typography>
                <form onSubmit={() => this.onSubmitHandler} method="post" className='cl-form' encType="multipart/form-data">
                    <TextField id="outlined-basic" label="name" fullWidth name="name" className='cl-text' onChange={this.changeHandler}  variant="outlined" sx={{
                        '& > :not(style)': { m: 1},
                    }}/>

                    <TextField
                        id="outlined-multiline-static"
                        label="description"
                        name="description" 
                        multiline
                        fullWidth
                        rows={6}
                        onChange={this.changeHandler}
                        className='cl-text'
                        sx={{
                            '& > :not(style)': { m: 1},
                        }}
                    />
                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">Type</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="1"
                            onChange={this.changeHandler} 
                            name="type"
                            sx={{
                                display:"flex",
                                flexDirection: 'row'
                            }}
                        >
                            <FormControlLabel value={1} control={<Radio />} label="Subgraph" />
                            <FormControlLabel value={2} control={<Radio />} label="Spider" />
                        </RadioGroup>
                    </FormControl>
                    { type === 1 ?
                    <div >
                        <TextField id="outlined-basic" label="thegraph application API" fullWidth name="apiurl" className='cl-text' onChange={this.changeHandler}  variant="outlined" sx={{
                            '& > :not(style)': { m: 1},
                        }}/>
            
                        <TextField
                            id="outlined-multiline-static"
                            label="thegraph sql"
                            name="apisql" 
                            multiline
                            fullWidth
                            rows={6}
                            onChange={this.changeHandler}
                            className='cl-text'
                            sx={{
                                '& > :not(style)': { m: 1},
                            }}
                        />
                    </div>
                    :
                    <FormControl fullWidth sx={{
                        marginLeft: "8px",
                        marginBottom : "8px",
                      }}>
                      <InputLabel id="demo-simple-select-labela">Spider Id</InputLabel>
                      <Select
                          labelId="demo-simple-select-labela"
                          name="spider_id"
                          value={spider_id}
                          label="Spider Id"
                          onChange={this.changeHandler}
                      >
                      { spider? spider.map((row, index) => (

                             <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                      )):  
                             <MenuItem  value="">mo data</MenuItem>
                      }
                      </Select>
                  </FormControl>
                    }
                    <br/>
                    <Button sx={{marginTop:"30px"}} variant="contained" onClick={this.onSubmitHandler}>Submit</Button>
                    <br/>
                </form>
            </Box>
            </Modal>
            <Modal
            open={openEdit}
            onClose={this.handleCloseEdit}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                 Update
                </Typography>
                <form onSubmit={() => this.onSubmitHandler} method="post" className='cl-form' encType="multipart/form-data">
                    <TextField id="outlined-basic" label="name" fullWidth name="name" className='cl-text' defaultValue={editData.name}  onChange={this.changeHandler}  variant="outlined" sx={{
                        '& > :not(style)': { m: 1 },
                    }}/>
                    <TextField
                        id="outlined-multiline-static"
                        label="description"
                        name="description" 
                        multiline
                        fullWidth
                        rows={6}
                        defaultValue={editData.description}
                        onChange={this.changeHandler}
                        className='cl-text'
                        sx={{
                            '& > :not(style)': { m: 1 },
                        }}
                    />
                   <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">Type</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={Number(type)}
                            onChange={this.changeHandler} 
                            name="type"
                            sx={{
                                display:"flex",
                                flexDirection: 'row'
                            }}
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Subgraph" />
                            <FormControlLabel value="2" control={<Radio />} label="Spider" />
                        </RadioGroup>
                    </FormControl>
                    { type === 1 ?
                    <div >
                        <TextField id="outlined-basic" label="thegraph application API" fullWidth name="apiurl" defaultValue={editData.apiurl} className='cl-text' onChange={this.changeHandler}  variant="outlined" sx={{
                            '& > :not(style)': { m: 1},
                        }}/>
            
                        <TextField
                            id="outlined-multiline-static"
                            label="thegraph sql"
                            name="apisql" 
                            multiline
                            fullWidth
                            rows={6}
                            defaultValue={editData.apisql}
                            onChange={this.changeHandler}
                            className='cl-text'
                            sx={{
                                '& > :not(style)': { m: 1},
                            }}
                        />
                    </div>
                    :
                    <FormControl fullWidth sx={{
                        marginLeft: "8px",
                        marginBottom : "8px",
                      }}>
                      <InputLabel id="demo-simple-select-labela">Spider Id</InputLabel>
                      <Select
                          labelId="demo-simple-select-labela"
                          name="spider_id"
                          value={spider_id}
                          label="Spider Id"
                          onChange={this.changeHandler}
                      >
                      { spider? spider.map((row, index) => (

                             <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                      )):  
                             <MenuItem  value="">mo data</MenuItem>
                      }
                      </Select>
                    </FormControl>
                    }
                    <br/>
                    <Button  sx={{marginTop:"30px"}}  variant="contained" onClick={this.onSubmitHandlerUpdate}>Submit</Button>
                    <br/>
                </form>
            </Box>
            </Modal>
            <Dialog
                open={delopen}
                onClose={this.handleCloseDel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">
             {"Tips"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Are you sure to delete this data?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={this.handleClickDelete}  sx={{color:"#1236ff"}}>confirm</Button>
            <Button onClick={this.handleCloseDel} autoFocus>
            cancel
            </Button>
            </DialogActions>
           </Dialog>
           <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={this.handleSnackbarClose} anchorOrigin={{ vertical, horizontal }}>
                <Alert onClose={this.handleSnackbarClose} severity={tip.status} sx={{ width: '100%' }}>
                     {tip.message}
                </Alert>
          </Snackbar>
            </HomeWrapper>
        )
    }
}

const mapState = (state) => ({
    opensea: state.getIn(['header', 'opensea']),
    chainId: state.getIn(['header', 'chainId']),
    account: state.getIn(['header', 'account']),
    myNftAssets: state.getIn(['header', 'myNftAssets']),
})


export default connect(mapState)(Profile);

