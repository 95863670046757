import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { HomeWrapper } from './style'
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { BASE_SITE_API } from '../../../../config/setting'
import { Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CardMedia from '@mui/material/CardMedia';
import axios from "axios";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
// import {EditorState, ContentState} from 'draft-js';
// import 'draft-js/dist/Draft.css';
// import { Editor } from 'react-draft-wysiwyg';
// import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import dtohtml from "draftjs-to-html"
// import htmlToDraft from 'html-to-draftjs'
import moment from 'moment';
// import { Link } from 'react-router-dom'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import XNav from "../../common/xnav";

const vertical = 'top'
const horizontal = 'right'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
// const editorStyle = {
//      border: "1px solid #000000",
//      minHeight: "100px"
// };

class Course extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {anchorElNav: '', SnackbarOpen: false, message: '', title: '', video: '', description: '', cover: '', resourceRows: "", openAdd: false, openEdit: false, editData: "", delData: "", id: "", editorState: "", contentState: "", delopen: false, openSnackbar: false, tip:{status:"info", message:""}, course_video_id: ""}
    } 

    componentDidMount(){
        this.loadData()
        var authorization = sessionStorage.getItem("authorization")
        if(!authorization) {
            this.props.history.push('/admin/login')
        }
    }

    componentDidUpdate(){
        var authorization = sessionStorage.getItem("authorization")
        if(!authorization) {
            this.props.history.push('/admin/login')
        }
    }

    loadData = () => {
        axios({
            method:"get",
            url:`${BASE_SITE_API}/course/courseVideoList/${this.props.match.params.tokenId}`,
        }).then(res=>{
            if(res) {
                this.setState({
                    resourceRows: res.data.data
                })
            }else{
                alert('error: login fail')
            }
            
        }).catch(err => {
            console.log(err)
        })
    }

    handleOpenNavMenu =  (event) => {
        this.setState({
          anchorElNav: event.currentTarget
        })
    }

    handleOpenDoc =  (e) => {
        if(this.props.account){
            this.props.history.push('/renderer/'+ encodeURIComponent(e))
        }else{
            this.setState({
                SnackbarOpen: true,
                message: "Please click the wallet in the upper right corner to login"
            });
        }
    }

    handleCloseSnackbar = () => {
        this.setState({
            SnackbarOpen: false
        });
    }
    
    fileChangeHandlerImage = (e) => {
        this.setState({
            cover: e.target.files[0]
        })
    }

    fileChangeHandlerVideo = (e) => {
        this.setState({
            video: e.target.files[0]
        })
    }

    fileChangeHandlerDocPath = (e) => {
        this.setState({
            doc_path: e.target.files[0]
        })
    }

    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleClickAdd = () => {
        this.setState({
            openAdd:true
        })
    }

    handleCloseAdd = () => {
        this.setState({
            openAdd:false
        })
    }
    handleCloseDel = () => {
        this.setState({
            delopen:false
        })
    };

    handleClickDelOpen = (row) => {
        this.setState({
            delopen:true,
            delData:row
        })
    };

    handleClickEdit = (row) => {
        this.setState({
            openEdit:true,
            id:row.id,
            contentState:row.content,
            title:row.title,
            price:row.price,
            description:row.description,
            editData: row
        })
    }

    handleClickDel = (id) => {
        
    }
    
    handleCloseEdit = () => {
        this.setState({
            openEdit:false
        })
    }

    showMessage = (status, message) => {
        this.setState({
            openSnackbar: true,
            tip: {
                status: status,
                message: message
            }
        })
    }

    onSubmitHandler = () => {
        const { title, description, cover, video } = this.state
        if(title && description && cover && video) {
            const data = new FormData();
            data.append("title", title);
            data.append("description", description);
            data.append("cover", cover);
            data.append("video", video);
            data.append("course_id", this.props.match.params.tokenId);
            axios({
                method:"POST",
                url:`${BASE_SITE_API}/video/`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'authorization': sessionStorage.getItem("authorization")
                },
                data
            }).then(res=>{
                if(res.data.code === 200){
                    this.setState({
                        openAdd: false
                    })
                    this.showMessage("success", "add success")
                    this.loadData()
                }else{
                    this.showMessage("error", "Add failed")
                }
            }).catch(err => {
                this.showMessage("error", err)
            })
    
        } else {
            this.showMessage("error", "Incomplete parameters")
        }
    }

    onSubmitHandlerUpdate = () => {
        const { title, description, cover, id, video, course_video_id } = this.state
        if(title && description && id && this.props.match.params.tokenId) {
            const data = new FormData();
            data.append("title", title);
            data.append("description", description);
            data.append("cover", cover);
            data.append("video", video);
            data.append("course_id", this.props.match.params.tokenId);
            data.append("course_video_id", course_video_id);
            axios({
                method:"PUT",
                url:`${BASE_SITE_API}/video/${id}`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'authorization': sessionStorage.getItem("authorization")
                },
                data
            }).then(res=>{
                if(res.data.code === 200){
                    this.setState({
                        openEdit: false
                    })
                    this.showMessage("success", "update success")
                    this.loadData()
                }else{
                    this.showMessage("error", "update failed")
                } 
            }).catch(err => {
                this.showMessage("error", err)
            })
        } else {
            this.showMessage("error", "Incomplete parameters")
        }
    }

    handleClickDelete = () => {
        const { delData } = this.state
        if(delData.id){
            axios({
                method:"delete",
                url:`${BASE_SITE_API}/video/${delData.id}`,
                headers: {
                    'authorization': sessionStorage.getItem("authorization")
                },
            }).then(res=>{
                if(res.data.code === 200){
                    this.handleCloseDel()
                    this.showMessage("success", "Delete success")
                    this.loadData()
                }else{
                    this.showMessage("error", "Delete failed")
                }
            }).catch(err => {
                this.showMessage("error", err)
            })
        }else{
              this.showMessage("error", "Incomplete parameters")
        }
    }

    handleSnackbarClose = () => {
        this.setState({
           openSnackbar:false
        })
    }

    contentStateChange(contentState) {
    this.setState({
        contentState:dtohtml(contentState)
    })
    }

    render() {
        const { resourceRows, openAdd, openEdit, editData, delopen, openSnackbar, tip } = this.state;
        return (
            <HomeWrapper>
        
                <Grid
                    className='cl-grid'
                >

                <XNav history={this.props.history}/>
                
                <Typography variant="h5" gutterBottom component="div">
                Course Video List
                </Typography>
                <Typography variant="body" gutterBottom component="div">
                    <Button variant="contained" onClick={this.handleClickAdd} sx={{ marginBottom: "20px" }}>ADD Course Video</Button>
                </Typography>
                 
                    <TableContainer component={Paper}>

                    <Table sx={{ minWidth: 650, marginTop: "10px" }} size="small" aria-label="a dense table">
                        <TableHead>
                        <TableRow>
                            <TableCell align="center">ID</TableCell>
                            <TableCell align="center">Title</TableCell>
                            <TableCell align="center">Description</TableCell>
                            <TableCell align="center">Cover</TableCell>
                            <TableCell align="center">Date</TableCell>
                            <TableCell align="center">Option</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        { resourceRows? resourceRows.map((row, index) => (
                            <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell align="center">{row.course_video_id}</TableCell>
                            <TableCell align="left">{row.title}</TableCell>
                            <TableCell align="left">{row.description}</TableCell>
                            <TableCell align="center">
                            <Typography sx={{ height: `30px`, width: "100px", display: "flex", justifyContent: "center", alignContent: "center" }} >
                                    <CardMedia
                                        component="img"
                                        image={row.cover}
                                        width= "100px"
                                        height={"100%"}
                                    />
                                </Typography>
                            </TableCell>
                            <TableCell align="center">{moment(row.create_date*1000).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                            <TableCell align="center">
                            <Button variant="contained" onClick={() =>this.handleClickEdit(row)}>Edit</Button>
                            <Button variant="contained" onClick={() =>this.handleClickDelOpen(row)} sx={{ marginLeft: "10px" }}>Delete</Button>
                            </TableCell>
                            </TableRow>
                        )):  
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="right">no data</TableCell>
                        </TableRow>
                        }
                        </TableBody>
                    </Table>
                </TableContainer>
                </Grid>
        <Modal
            open={openAdd}
            onClose={this.handleCloseAdd}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                 Add 
                </Typography>
                {/* MetaMask is a cryptocurrency wallet that allows its users to interact with Web 3 applications. It has over 30 million monthly active users globally and is an essential tool of Web3 space.  */}

                <form onSubmit={() => this.onSubmitHandler} method="post" className='cl-form' encType="multipart/form-data">
                    <TextField id="outlined-basic" label="Title" name="title" fullWidth className='cl-text' onChange={this.changeHandler}  variant="outlined" sx={{
                        '& > :not(style)': { m: 1  },
                    }}/>
      
                    <TextField
                        id="outlined-multiline-static"
                        label="Description"
                        name="description" 
                        multiline
                        fullWidth
                        rows={6}
                        onChange={this.changeHandler}
                        className='cl-text'
                        sx={{
                            '& > :not(style)': { m: 1},
                        }}
                    />

                    <br/>
                    {/* <Editor editorState={editorState} onEditorStateChange={this.editorStateChange.bind(this)} editorStyle={editorStyle}
                            onContentStateChange={this.contentStateChange.bind(this)}></Editor> */}
                    <br/>
                    

                    <Typography variant="body2" component="div" sx={{  paddingBottom:"30px", paddingTop:"10px"}}>
                    <span>Cover：</span>
                        <input type="file"  onChange={this.fileChangeHandlerImage} placeholder=""/>
                   </Typography>
                    <div className='cl-text'>
                        <span>Video：</span>
                        <input type="file"  onChange={this.fileChangeHandlerVideo} placeholder=""/>
                    </div>

                    <Button 
                             sx={{
                                marginTop:"30px"
                              }}
                    variant="contained" onClick={this.onSubmitHandler}>Submit</Button>

                    <br/>
                </form>
            </Box>
            </Modal>
            <Modal
            open={openEdit}
            onClose={this.handleCloseEdit}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                 Update
                </Typography>
             
                <form onSubmit={() => this.onSubmitHandler} method="post" className='cl-form' encType="multipart/form-data">
                    <TextField  label="Course video id" name="course_video_id" className='cl-text' fullWidth  defaultValue={editData.course_video_id} onChange={this.changeHandler}   variant="outlined" sx={{
                        '& > :not(style)': { m: 1, width: '100%' },
                    }}/>
                    <TextField  label="Title" name="title" className='cl-text' fullWidth  defaultValue={editData.title} onChange={this.changeHandler}   variant="outlined" sx={{
                        '& > :not(style)': { m: 1, width: '100%' },
                    }}/>
                    <TextField
                        label="description"
                        name="description" 
                        defaultValue={editData.description}
                        multiline
                        fullWidth
                        rows={6}
                        onChange={this.changeHandler}
                        className='cl-text'
                        sx={{
                            '& > :not(style)': { m: 1},
                        }}
                    />
                    <br/>
                    {/* <Editor editorState={editorState} onEditorStateChange={this.editorStateChange.bind(this)} editorStyle={editorStyle}
                            onContentStateChange={this.contentStateChange.bind(this)}></Editor> */}
                   <Typography variant="body2" component="div" sx={{  paddingBottom:"30px", paddingTop:"10px"}}>
                        <span>Cover：</span>
                        <input type="file"  onChange={this.fileChangeHandlerImage} placeholder=""/>
                   </Typography>
                    <div className='cl-text'>
                        <span>Video：</span>
                        <input type="file"  onChange={this.fileChangeHandlerVideo} placeholder=""/>
                    </div>
                    <Button 
                    sx={{
                      marginTop:"30px"
                    }}
                    variant="contained" onClick={this.onSubmitHandlerUpdate}>Submit</Button>
                    <br/>
                </form>
            </Box>
            </Modal>
            <Dialog
                open={delopen}
                onClose={this.handleCloseDel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">
             {"Tips"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Are you sure to delete this data?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={this.handleClickDelete}  sx={{color:"#1236ff"}}>confirm</Button>
            <Button onClick={this.handleCloseDel} autoFocus>
            cancel
            </Button>
            </DialogActions>
           </Dialog>
           <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={this.handleSnackbarClose} anchorOrigin={{ vertical, horizontal }}>
                <Alert onClose={this.handleSnackbarClose} severity={tip.status} sx={{ width: '100%' }}>
                     {tip.message}
                </Alert>
          </Snackbar>
            </HomeWrapper>
        )
    }

}

const mapState = (state) => ({
    opensea: state.getIn(['header', 'opensea']),
    chainId: state.getIn(['header', 'chainId']),
    account: state.getIn(['header', 'account']),
    myNftAssets: state.getIn(['header', 'myNftAssets']),
})

export default connect(mapState)(Course);