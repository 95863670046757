import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { HomeWrapper } from './style'
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import { BASE_SITE_API } from '../../../../config/setting'
import { Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CardMedia from '@mui/material/CardMedia';
import axios from "axios";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import {EditorState, ContentState} from 'draft-js';
import 'draft-js/dist/Draft.css';
import { Editor } from 'react-draft-wysiwyg';
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import dtohtml from "draftjs-to-html"
import htmlToDraft from 'html-to-draftjs'
import moment from 'moment';
// import { Link } from 'react-router-dom'
import Alert from '@mui/material/Alert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import XNav from "../../common/xnav";

import Web3 from 'web3'
import { COURSE_TOKEN_ADDRESS, COURSE_TOKEN_ABI } from '../../../../config/setting'
const vertical = 'top'
const horizontal = 'right'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
const editorStyle = {
     border: "1px solid #000000",
     minHeight: "100px"
  };

class Course extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {anchorElNav: '', SnackbarOpen: false, message: '', title: '', price: '', description: '', cover: '', resourceRows: "", openAdd: false, openEdit: false, editData: "", id: "", editorState: EditorState.createEmpty(), contentState: "", openSnackbar: false, tip:{status:"info", message:""}, openSetPrice: false, openHistoryPrice: false, historyPriceList:""}
    } 

    componentDidMount(){
        this.loadData()
        var authorization = sessionStorage.getItem("authorization")
        if(!authorization) {
            this.props.history.push('/admin/login')
        }
    }

    componentDidUpdate(){
        var authorization = sessionStorage.getItem("authorization")
        if(!authorization) {
            this.props.history.push('/admin/login')
        }
    }

    loadData = () => {
        axios({
            method:"get",
            url:`${BASE_SITE_API}/course/`,
        }).then(res=>{
            if(res) {
                this.setState({
                    resourceRows: res.data.data
                })
            }else{
                alert('error: login fail')
            }
            
        }).catch(err => {
            console.log(err)
        })
    }
    handleOpenNavMenu =  (event) => {
        this.setState({
          anchorElNav: event.currentTarget
        })
    }

    handleOpenVideoList =  (e) => {
        if(e){
            this.props.history.push('/admin/course/'+ encodeURIComponent(e))
        }else{
            this.setState({
                SnackbarOpen: true,
                message: "Please click the wallet in the upper right corner to login"
            });
        }
    }

    handleCloseSnackbar = () => {
        this.setState({
            SnackbarOpen: false
        });
    }
    
    fileChangeHandlerImage = (e) => {
        this.setState({
            cover: e.target.files[0]
        })
    }

    fileChangeHandlerDocPath = (e) => {
        this.setState({
            doc_path: e.target.files[0]
        })
    }

    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleClickAdd = () => {
        this.setState({
            openAdd:true
        })
    }

    handleCloseAdd = () => {
        this.setState({
            openAdd:false
        })
    }

    handleCloseSetPrice = () => {
        this.setState({
            openSetPrice: false
        })
    }

    handleClickEdit = (row) => {
        if (row.content) {
            const contentBlock = htmlToDraft(row.content);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.setState({
                editorState
            })
        }
        this.setState({
            openEdit:true,
            id:row.id,
            contentState:row.content,
            title:row.title,
            price:row.price,
            description:row.description,
            editData: row
        })
    }

    handleClickSetPrice = (row) => {

        this.setState({
            openSetPrice: true,
            id:row.id,
            // contentState:row.content,
            // title:row.title,
            // price:row.price,
            // description:row.description,
            // editData: row
        })
    }
    
    handleCloseEdit = () => {
        this.setState({
            openEdit:false
        })
    }

    showMessage = (status, message) => {
        this.setState({
            openSnackbar: true,
            tip: {
                status: status,
                message: message
            }
        })
    }

    onSubmitHandler = () => {
        const { title, description, cover, contentState } = this.state
        if(title && description && cover) {
            const data = new FormData();

            data.append("title", title);
            data.append("description", description);
            data.append("content", contentState);
            data.append("cover", cover);
            axios({
                method:"POST",
                url:`${BASE_SITE_API}/course/`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'authorization': sessionStorage.getItem("authorization")
                },
                data
            }).then(res=>{
                if(res.data.code === 200){
                    this.setState({
                        openAdd: false
                    })
                    this.showMessage("success", "add success")
                    this.loadData()
                }else{
                    this.showMessage("error", "Add failed")
                }
            }).catch(err => {
                this.showMessage("error", err)
            })
        } else {
            this.showMessage("error", "Incomplete parameters")
        }
    }

    onSubmitHandlerUpdate = () => {
        const { title, description, cover, id, contentState } = this.state
        if(title && description && id && contentState) {
            const data = new FormData();
            data.append("id", id);
            data.append("title", title);
            data.append("description", description);
            data.append("content", contentState);
            data.append("cover", cover);
            axios({
                method:"PUT",
                url:`${BASE_SITE_API}/course/${id}`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'authorization': sessionStorage.getItem("authorization")
                },
                data
            }).then(res=>{
                if(res.data.code === 200){
                    this.setState({
                        openEdit: false
                    })
                    this.showMessage("success", "update success")
                    this.loadData()
                }else{
                    this.showMessage("error", "update failed")
                }
            }).catch(err => {
                this.showMessage("error", err)
            })
        } else {
            this.showMessage("error", "Incomplete parameters")
        }
    }

    onSubmitHandlerSetPrice = async () => {
        const { id, price } = this.state
        const { account,  walletProvider } = this.props
        if( price && id && account ) {
            var webObj = new Web3(walletProvider)
            var myContract = new webObj.eth.Contract(COURSE_TOKEN_ABI, COURSE_TOKEN_ADDRESS)
            //  var price = await Web3.utils.toWei(DOC_PRICE, 'ether')
            await myContract.methods.setCoursePrice(id, price).send({from:account}).then((res)=>{
                console.log(res)
                if(res){
                  this.setState({
                    openSetPrice: false,
                  })
                  this.showMessage("success", "set price success")
                }
            }).catch(err=>{
                this.showMessage("error", err.message)
            })
        } else {
            this.showMessage("error", "Incomplete parameters")
        }
    }


    handleClickUpdatePrice = async  (id) => {
        if(id) {
            const { account,  walletProvider } = this.props
            var webObj = new Web3(walletProvider)
            var myContract = new webObj.eth.Contract(COURSE_TOKEN_ABI, COURSE_TOKEN_ADDRESS)
            myContract.methods.getCoursePrice(id).call({ from: account }).then((res)=>{
               var price =  Web3.utils.fromWei(res, 'ether')
                if(res){
                    const data = new FormData();
                    data.append("price", price);
                    axios({
                        method:"PUT",
                        url:`${BASE_SITE_API}/course/setCoursePrice/${id}`,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'authorization': sessionStorage.getItem("authorization")
                        },
                        data
                    }).then(res=>{
                        if(res.data.code === 200){
                            this.setState({
                                openEdit: false
                            })
                            this.showMessage("success", "update success")
                            this.loadData()
                        }else{
                            this.showMessage("error", "update failed")
                        }
                    }).catch(err => {
                        this.showMessage("error", err)
                    })
                }
            }).catch(err =>{
                this.showMessage("error", err.message)
                console.log(err)
            })
        }
    }

    handleClickUpdatePriceHistoryShow = async (id) => {
        if(id) {
            const { account,  walletProvider } = this.props
            var webObj = new Web3(walletProvider)
            var myContract = new webObj.eth.Contract(COURSE_TOKEN_ABI, COURSE_TOKEN_ADDRESS)
            myContract.methods.getCoursePriceHistory(id).call({ from: account }).then((res)=>{
                this.setState({
                    openHistoryPrice: true,
                    historyPriceList: res
                })
            }).catch(err =>{
                this.showMessage("error", err.data)
                console.log(err)
            })
        }
    }
    handleCloseHistoryPrice = ()=> {
        this.setState({
            openHistoryPrice: false,
        })
    }

    editorStateChange(editorState) {
        this.setState({
          editorState
        })
      }

      contentStateChange(contentState) {
        this.setState({
            contentState:dtohtml(contentState)
        })
      }

    render() {
        const { resourceRows, openAdd, openEdit, editData, editorState, openSnackbar, tip, openSetPrice, openHistoryPrice, historyPriceList} = this.state;
        return (
            <HomeWrapper>
        
                <Grid
                    className='cl-grid'
                >

                <XNav history={this.props.history}/>
                
                <Typography variant="h5" gutterBottom component="div">
                Course
                </Typography>
                <Typography variant="body" gutterBottom component="div">
                    <Button variant="contained" onClick={this.handleClickAdd} sx={{ marginBottom: "20px" }}>ADD Course</Button>
                </Typography>
                 
                    <TableContainer component={Paper}>

                    <Table sx={{ minWidth: 650, marginTop: "10px" }} size="small" aria-label="a dense table">
                        <TableHead>
                        <TableRow>
                            <TableCell align="center">ID</TableCell>
                            <TableCell align="center">Title</TableCell>
                            <TableCell align="center">Description</TableCell>
                            <TableCell align="center">Price（MATIC）</TableCell>
                            <TableCell align="center">cover</TableCell>
                            <TableCell align="center">Set the price on the chain</TableCell>
                            <TableCell align="center">Date</TableCell>
                            <TableCell >Option</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        { resourceRows? resourceRows.map((row, index) => (
                            <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell align="center">{row.id}</TableCell>
                            <TableCell align="left">{row.title}</TableCell>
                            <TableCell align="left">{row.description}</TableCell>
                            <TableCell align="left">{row.price}</TableCell>
                            <TableCell align="center">
                            <Typography sx={{ height: `30px`, display: "flex", justifyContent: "center", alignContent: "center" }} >
                                    <CardMedia
                                        component="img"
                                        image={row.cover}
                                        width= "100%"
                                        height={"100%"}
                                    />
                                    </Typography>
                            </TableCell>
                            <TableCell align="center"> <Button variant="contained" onClick={() =>this.handleClickSetPrice(row)}>set price</Button></TableCell>
                            <TableCell align="center">{moment(row.create_date*1000).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                            <TableCell align="center">
                            <Button variant="contained" onClick={() =>this.handleClickEdit(row)}>Edit</Button>
                            <Button variant="contained" onClick={() =>this.handleOpenVideoList(row.id)} sx={{ marginLeft: "10px" }}>Video List</Button>
                            <Button variant="contained" onClick={() =>this.handleClickUpdatePrice(row.id)} sx={{ marginLeft: "10px" }}>Update Price</Button>
                            <Button variant="contained" onClick={() =>this.handleClickUpdatePriceHistoryShow(row.id)} sx={{ marginLeft: "10px" }}>Price History</Button>
                            </TableCell>
                            </TableRow>
                        )):  
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="right">no data</TableCell>
                        </TableRow>
                        
                        }
                        </TableBody>
                    </Table>
                </TableContainer>
                </Grid>
        <Modal
            open={openAdd}
            onClose={this.handleCloseAdd}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                 Add 
                </Typography>
                {/* MetaMask is a cryptocurrency wallet that allows its users to interact with Web 3 applications. It has over 30 million monthly active users globally and is an essential tool of Web3 space.  */}

                <form onSubmit={() => this.onSubmitHandler} method="post" className='cl-form' encType="multipart/form-data">
                    <TextField id="outlined-basic" label="Title" name="title" fullWidth className='cl-text' onChange={this.changeHandler}  variant="outlined" sx={{
                        '& > :not(style)': { m: 1  },
                    }}/>
      
                    <TextField
                        id="outlined-multiline-static"
                        label="Description"
                        name="description" 
                        multiline
                        fullWidth
                        rows={6}
                        onChange={this.changeHandler}
                        className='cl-text'
                        sx={{
                            '& > :not(style)': { m: 1},
                        }}
                    />

                    <br/>
                    <Editor editorState={editorState} onEditorStateChange={this.editorStateChange.bind(this)} editorStyle={editorStyle}
                            onContentStateChange={this.contentStateChange.bind(this)}></Editor>
                    <br/>
                    <div className='cl-text'>
                        <span>cover：</span>
                        <input type="file" onChange={this.fileChangeHandlerImage} placeholder=""/>
                    </div>

                    <Button 
                             sx={{
                                marginTop:"30px"
                              }}
                    variant="contained" onClick={this.onSubmitHandler}>Submit</Button>

                    <br/>
                </form>
            </Box>
            </Modal>
            <Modal
            open={openEdit}
            onClose={this.handleCloseEdit}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                 Update
                </Typography>
             
                <form onSubmit={() => this.onSubmitHandler} method="post" className='cl-form' encType="multipart/form-data">
                    <TextField  label="Title" name="title" className='cl-text' fullWidth  defaultValue={editData.title} onChange={this.changeHandler}   variant="outlined" sx={{
                        '& > :not(style)': { m: 1, width: '100%' },
                    }}/>
                    <TextField
                        label="description"
                        name="description" 
                        defaultValue={editData.description}
                        multiline
                        fullWidth
                        rows={6}
                        onChange={this.changeHandler}
                        className='cl-text'
                        sx={{
                            '& > :not(style)': { m: 1},
                        }}
                    />
                    <br/>
                    <Editor editorState={editorState} onEditorStateChange={this.editorStateChange.bind(this)} editorStyle={editorStyle}
                            onContentStateChange={this.contentStateChange.bind(this)}></Editor>
                    <div className='cl-text'>
                        <span>cover：</span>
                        <input type="file" onChange={this.fileChangeHandlerImage} placeholder=""/>
                    </div>
                    <Button 
                    sx={{
                      marginTop:"30px"
                    }}
                    variant="contained" onClick={this.onSubmitHandlerUpdate}>Submit</Button>
                    <br/>
                </form>
            </Box>
            </Modal>
            <Modal
            open={openSetPrice}
            onClose={this.handleCloseSetPrice}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                   Set the price on the chain （Unit Wei）
                </Typography>
             
                <form method="post" className='cl-form' encType="multipart/form-data">
                    <TextField  label="Price" name="price" className='cl-text' fullWidth  onChange={this.changeHandler}   variant="outlined" sx={{
                        '& > :not(style)': { m: 1, width: '100%' },
                    }}/>
                    <Button 
                    sx={{
                      marginTop:"30px"
                    }}
                    variant="contained" onClick={this.onSubmitHandlerSetPrice}>Submit</Button>
                    <br/>
                </form>
            </Box>
            </Modal>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={this.handleSnackbarClose} anchorOrigin={{ vertical, horizontal }}>
                <Alert onClose={this.handleSnackbarClose} severity={tip.status} sx={{ width: '100%' }}>
                     {tip.message}
                </Alert>
          </Snackbar>

            <Dialog onClose={this.handleCloseHistoryPrice} open={openHistoryPrice}>
                <DialogTitle>Price History</DialogTitle>
                <List sx={{ pt: 0 }}>
                    {historyPriceList?historyPriceList.map((item, index) => (
                    <ListItem button key={index}>
                        {item} Wei
                    </ListItem>
                    )): "no set price" }
                </List>
            </Dialog>
            </HomeWrapper>
        )
    }
}

const mapState = (state) => ({
    walletProvider: state.getIn(['header', 'walletProvider']),
    opensea: state.getIn(['header', 'opensea']),
    chainId: state.getIn(['header', 'chainId']),
    account: state.getIn(['header', 'account']),
    myNftAssets: state.getIn(['header', 'myNftAssets']),
})

export default connect(mapState)(Course);