import styled from "styled-components";

export const MainWrapper = styled.div`
  .btu-spac {
     margin-right: 10px;
  }
  .cl-header {
    background-color: #fff;
    color:#000 ;
  }
`
