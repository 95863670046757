import {
  Grid,
  Typography,
  Box,
} from "@mui/material";
import { FooterLogo, LinkList } from './style';
import { Colors } from "../../../assets/styles/theme";
import Link from '@mui/material/Link';
import logo from "../../../assets/images/logo.png";

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        background: Colors.shaft,
        color: "#b0b5bf",
        p: { xs: 4, md: 10 },
        pt: 12,
        pb: 12,
        fontSize: { xs: '12px', md: '14px' },
      }}
    >
      <Box
        sx={{
          maxWidth: '1200px',
          width: '100%',
          margin: '0 auto',
          px: { xs: 2, sm: 3 }, // 控制左右内边距
        }}
      >
        <Grid container spacing={2} alignItems="center">
          {/* 左侧 Logo */}
          <Grid item xs={12} md={6} lg={6} textAlign={{ xs: "center", md: "left" }}>
            <FooterLogo src={logo} alt="Company Logo" />
          </Grid>

          {/* 右侧信息和链接 */}
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="caption" display="block" textAlign={{ xs: "center", md: "right" }}>
              <i className="icon iconfont icon-locationfill"></i> 
              55 Ayer Rajah Crescent, #01-26, Singapore 139949
            </Typography>
            <Box
              sx={{
                mt: 4,
                color: "#b0b5bf",
                display: 'flex',
                justifyContent: { xs: 'center', md: 'flex-end' }, // 小屏幕居中对齐，大屏幕右对齐
                gap: 2,
                flexWrap: 'wrap', // 使得子元素在需要时换行
                '& > *': {
                  margin: 0 // 确保没有额外的边距
                }
              }}
            >
              <LinkList>
                <Link href="https://twitter.com/SerenityFund">CONTACT</Link>
                <Link href="/disclaimer">DISCLAIMER</Link>
                <Link href="https://serenityresearch.substack.com">
                  <i className="icon iconfont icon-medium"></i>
                </Link>
                <Link href="https://twitter.com/SerenityFund">
                  <i className="icon iconfont icon-twitter"></i>
                </Link>
              </LinkList>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
