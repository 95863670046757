
// import styled from "styled-components";
import { Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const HomeContainer = styled(Container)((theme) => ({
    padding: "30px 15px",
    height: "100vh",
    ".cl-content": {
      fontSize: "20px",
      lineHeight: "38px",
      "p": {
        // marginBottom: ".6rem",
        lineHeight: "1.6rem",
        "strong": {
          fontWeight: "bold"
        },
        "ins": {
          textDecoration: "underline"
        },
        "em": {
          fontStyle:"oblique"
        },
        "a": {
          color: "#c4c8cb"
        },
        "del": {
          textDecoration: "line-through"
        },
        "sup": {
          verticalAlign:"super"
        },
        "sub": {
          verticalAlign:"sub"
        }
      },
    "ol" : {
      listStyleType: "decimal",
      margin: 0,
      paddingLeft: "25px",
    }
    }
}));

export const GoodsPrice = styled(Typography)(() => ({
  color: "#000000",
  paddingTop:"10px" ,
  "span" : {
      color: "#1b0ded",
  }
}));