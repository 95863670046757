import React, { PureComponent } from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

class XNav extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {anchorElNav: ''}
    } 
    handleOpenLink =  (type) => {
        var linkUrl = "/admin/resource"
        if(type === 1){
            linkUrl = "/admin/prespectives"
        }else if(type === 3){
            linkUrl = "/admin/course"
        }else if(type === 4){
            linkUrl = "/admin/subgraph"
        }else if(type === 5){
            linkUrl = "/admin/spider"
        }else if(type === 6){
            linkUrl = "/admin/crontab"
        }
        this.props.history.push(linkUrl)
    }

    render() {
        return (
                <Typography variant="body" sx={{ marginBottom: "30px" }}>
                    <Button variant="outlined" sx={{ marginRight: "10px" }} onClick={()=>this.handleOpenLink(1)}>Prespectives</Button>
                    <Button variant="outlined" sx={{ marginRight: "10px" }} onClick={()=>this.handleOpenLink(2)}>Academy</Button>
                    <Button variant="outlined" sx={{ marginRight: "10px" }} onClick={()=>this.handleOpenLink(3)}>course</Button>
                    <Button variant="outlined" sx={{ marginRight: "10px" }} onClick={()=>this.handleOpenLink(4)}>subgraph api</Button>
                    <Button variant="outlined" sx={{ marginRight: "10px" }} onClick={()=>this.handleOpenLink(5)}>spider</Button>
                    <Button variant="outlined" sx={{ marginRight: "10px" }} onClick={()=>this.handleOpenLink(6)}>crontab</Button>
                </Typography>
        )
    }
}

export default XNav;

