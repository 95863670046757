import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { HomeWrapper } from './style'
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { BASE_SITE_API } from '../../../config/setting'
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from "axios";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import moment from 'moment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import XNav from "../common/xnav";

const vertical = 'top'
const horizontal = 'right'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
const msgStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
  };
  
class Profile extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {anchorElNav: '', SnackbarOpen: false, message: '', name: '', description: '', dependent: "",  environment_version: '', url:'', prespectivesRows: "", openAdd: false, openEdit: false, editData: "", delData: "", id:"", dependent_modules:"", delopen:false, openSnackbar: false, runModal: false, installOpen: false, tip:{status:"info", message:""}, script_path: "", runMsg: ""}
    } 

    componentDidMount(){
        this.loadData()
        var authorization = sessionStorage.getItem("authorization")
        if(!authorization) {
            this.props.history.push('/admin/login')
        }
    }

    componentDidUpdate(){
        // this.loadData()
        var authorization = sessionStorage.getItem("authorization")
        if(!authorization) {
            this.props.history.push('/admin/login')
        }
    }
    loadData = () => {
        axios({
            method:"get",
            url:`${BASE_SITE_API}/spider`,
        }).then(res=>{
            if(res) {
                this.setState({
                    prespectivesRows: res.data.data
                })
            }else{
                alert('error: login fail')
            }
            
        }).catch(err => {
            console.log(err)
        })
    }

    handleOpenNavMenu =  (event) => {
        this.setState({
          anchorElNav: event.currentTarget
        })
    }

    handleOpenDoc =  (e) => {
        if(this.props.account){
            this.props.history.push('/renderer/'+ encodeURIComponent(e))
        }else{
            this.setState({
                SnackbarOpen: true,
                message: "Please click the wallet in the upper right corner to login"
            });
        }
    }

    handleCloseSnackbar = () => {
        this.setState({
            SnackbarOpen: false
        });
    }
    
    fileChangeHandlerImage = (e) => {
        this.setState({
            script_path: e.target.files[0]
        })
    }

    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleClickAdd = () => {
        this.setState({
            openAdd:true
        })
    }

    handleCloseAdd = (e) => {
        this.setState({
            openAdd:false
        })
    }

    handleClickEdit = (row) => {
        this.setState({
            openEdit:true,
            name:row.name,
            description:row.description,
            script_path:row.script_path,
            environment_version:row.environment_version,
            dependent_modules:row.dependent_modules,
            id:row.id,
            editData: row
        })
    }
    
    handleCloseEdit = () => {
        this.setState({
            openEdit: false
        })
    }

    handleCloseRunModal = () => {
        this.setState({
            runModal: false
        })
    }

    handleCloseInstall = () => {
        this.setState({
            installOpen: false
        })
    }

    handleClickShowPipList = () => {
        axios({
            method:"POST",
            url:`${BASE_SITE_API}/spider/showPipList`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'authorization': sessionStorage.getItem("authorization")
            }
        }).then(res=>{
            if(res.data.code === 200){
                this.setState({
                    runModal: true,
                    runMsg: res.data.data
                })
            }else{
                console.log(res.data.data)
            }
        }).catch(err => {
            this.showMessage("error", err)
        })
    }

    handleClickInstall = () => {
        var { dependent } = this.state
        const data = new FormData();
   
        data.append("dependent", dependent);
        axios({
            method:"POST",
            url:`${BASE_SITE_API}/spider/installationDependencies`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'authorization': sessionStorage.getItem("authorization")
            },
            data
        }).then(res=>{
            if(res.data.code === 200){
                this.setState({
                    runModal: true,
                    runMsg: res.data.data
                })
            }else{
                console.log(res.data.data)
            }
        }).catch(err => {
            this.showMessage("error", err)
        })
    }

    showMessage = (status, message) => {
        this.setState({
            openSnackbar: true,
            tip: {
                status: status,
                message: message
            }
        })
    }
    handleClickRunTest = (id) => {
            axios({
                method:"POST",
                url:`${BASE_SITE_API}/spider/runTestSpider/${id}`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'authorization': sessionStorage.getItem("authorization")
                }
            }).then(res=>{
                if(res.data.code === 200){
                    // console.log(res.data.data)
                    this.setState({
                        runModal: true,
                        runMsg: res.data.data
                    })
                }else{
                    console.log(res.data.data)
                }
            }).catch(err => {
                this.showMessage("error", err)
            })
    }

    onSubmitHandler = () => {
        const { name, description, environment_version, script_path } = this.state

        if(name && description && environment_version && script_path) {
            const data = new FormData();
   
            data.append("name", name);
            data.append("description", description);
            data.append("environment_version", environment_version);
            data.append("script_path", script_path);

            axios({
                method:"POST",
                url:`${BASE_SITE_API}/spider/`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'authorization': sessionStorage.getItem("authorization")
                },
                data
            }).then(res=>{
                if(res.data.code === 200){
                    this.setState({
                        openAdd: false
                    })
                    this.showMessage("success", "add success")
                    this.loadData()
                }else{
                    this.showMessage("error", "Add failed")
                }
            }).catch(err => {
                this.showMessage("error", err)
            })
        } else {
            this.showMessage("error", "Incomplete parameters")
        }
    }
    
    onSubmitHandlerUpdate = () => {
        const { name, description, environment_version, id, script_path } = this.state

        if(name && description && id && environment_version ) {
            const data = new FormData();
   
            data.append("id", id);
            data.append("name", name);
            data.append("description", description);
            data.append("environment_version", environment_version);
            data.append("script_path", script_path);

            axios({
                method:"PUT",
                url:`${BASE_SITE_API}/spider/${id}`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'authorization': sessionStorage.getItem("authorization")
                },
                data
            }).then(res=>{
                if(res.data.code === 200){
                    this.setState({
                        openEdit: false
                    })
                    this.showMessage("success", "update success")
                    this.loadData()
                }else{
                    this.showMessage("error", "update failed")
                }
            }).catch(err => {
                this.showMessage("error", err)
            })
        } else {
            this.showMessage("error", "Incomplete parameters")
        }
    }

    handleClickDelete = () => {
        const { delData } = this.state
        if(delData.id){
            axios({
                method:"delete",
                url:`${BASE_SITE_API}/spider/${delData.id}`,
                headers: {
                    'authorization': sessionStorage.getItem("authorization")
                },
            }).then(res=>{
                if(res.data.code === 200){
                    this.handleCloseDel()
                    this.showMessage("success", "Delete success")
                    this.loadData()
                }else{
                    this.showMessage("error", "Delete failed")
                }
            }).catch(err => {
                this.showMessage("error", err)
            })
        }else{
            this.showMessage("error", "Incomplete parameters")
        }
    }

    handleClickDependent = (id) => {
        this.setState({
            installOpen: true,
            id: id,
        })
    };

    handleCloseDel = () => {
        this.setState({
            delopen:false
        })
    };

    handleClickDelOpen = (row) => {
        this.setState({
            delopen:true,
            delData:row
        })
    }
    




    render() {
        // const { myNftAssets } = this.props
        const { prespectivesRows, openAdd, openEdit, editData, delopen, openSnackbar, tip, environment_version, runModal, runMsg, installOpen} = this.state;
        return (
            <HomeWrapper>
                <Grid
                    className='cl-grid'
                >
                <XNav history={this.props.history}/>

                <Typography variant="h5" gutterBottom component="div">
                        Add spider
                </Typography>
                <Typography sx={{ marginBottom: "30px" }}  gutterBottom component="div">
                    <Button variant="contained" onClick={this.handleClickAdd}>ADD</Button>
                    <Button variant="contained" onClick={this.handleClickDependent} sx={{ marginLeft: "10px" }}>Dependent Modules</Button>
                    <Button variant="contained" onClick={this.handleClickShowPipList} sx={{ marginLeft: "10px" }}>pip list</Button>
                </Typography>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                        <TableRow>
                            <TableCell align="center">ID</TableCell>
                            <TableCell align="center">Title</TableCell>
                            <TableCell align="center">Description</TableCell>
                            <TableCell align="center">Python Version</TableCell>
                            {/* <TableCell align="center">Dependent Modules</TableCell> */}
                            <TableCell align="center">Script Path</TableCell>
                            <TableCell align="center">Create Date</TableCell>
                            <TableCell align="center">Update Date</TableCell>
                            <TableCell align="center">Option</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        { prespectivesRows? prespectivesRows.map((row, index) => (
                            <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell align="center">{row.id}</TableCell>
                            <TableCell align="left">{row.name}</TableCell>
                            <TableCell align="left">{row.description}</TableCell>
                            <TableCell align="left">{row.environment_version}</TableCell>
                            {/* <TableCell align="left">{row.dependent_modules}</TableCell> */}
                            <TableCell align="left">{row.script_path}</TableCell>
                            <TableCell align="center">{moment(row.create_time*1000).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                            <TableCell align="center">{moment(row.update_time*1000).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                            <TableCell align="center" sx={{ display: "flex",width: "290px" }}>
                             <Button variant="contained" onClick={() =>this.handleClickEdit(row)}>Edit</Button>
                             <Button variant="contained" onClick={() =>this.handleClickDelOpen(row)} sx={{ marginLeft: "10px" }}>Delete</Button>
                             <Button variant="contained" onClick={() =>this.handleClickRunTest(row.id)} sx={{ marginLeft: "10px" }}>RUN TEST</Button>
                          
                             {/* <Button variant="contained" onClick={() =>this.handleClickOpenLog(row)} sx={{ marginLeft: "10px" }}>LOG</Button> */}
                            </TableCell>
             
                            {/* <TableCell  sx={{ display: "flex" }}> */}
                   
                            {/* </TableCell> */}
                            </TableRow>
                        )):  
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                               <TableCell align="right">no data</TableCell>
          
                        </TableRow>
                        
                        }
                        </TableBody>
                    </Table>
                </TableContainer>
                </Grid>   
            <Modal
            open={openAdd}
            onClose={this.handleCloseAdd}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                Add 
                </Typography>
                <form onSubmit={() => this.onSubmitHandler} method="post" className='cl-form' encType="multipart/form-data">
                    <TextField id="outlined-basic" label="Name" fullWidth name="name" className='cl-text' onChange={this.changeHandler}  variant="outlined" sx={{
                        '& > :not(style)': { m: 1},
                    }}/>

                    <TextField
                        id="outlined-multiline-static"
                        label="description"
                        name="description" 
                        multiline
                        fullWidth
                        rows={6}
                        onChange={this.changeHandler}
                        className='cl-text'
                        sx={{
                            '& > :not(style)': { m: 1},
                        }}
                    />
                    <FormControl fullWidth sx={{
                        marginLeft: "8px",
                    }}>
                        <InputLabel id="demo-simple-select-label">Python Version</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="environment_version"
                        value={environment_version}
                        label="Python Version"
                        onChange={this.changeHandler}
                        >
                        <MenuItem value="3.7.5">3.7.5</MenuItem>
                        </Select>
                    </FormControl>
                    <Typography variant="body2" component="div" sx={{ paddingTop:"10px"}}>
                        <span>Script：</span>
                        <input type="file"  onChange={this.fileChangeHandlerImage} placeholder=""/>
                    </Typography>

                    <br/>
                    <Button sx={{marginTop:"30px"}} variant="contained" onClick={this.onSubmitHandler}>Submit</Button>
                    <br/>
                </form>
            </Box>
            </Modal>
            <Modal
            open={openEdit}
            onClose={this.handleCloseEdit}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                 Update
                </Typography>
                <form onSubmit={() => this.onSubmitHandler} method="post" className='cl-form' encType="multipart/form-data">
                    <TextField id="outlined-basic" label="Name" disabled fullWidth name="name" className='cl-text' defaultValue={editData.name}  onChange={this.changeHandler}  variant="outlined" sx={{
                        '& > :not(style)': { m: 1 },
                    }}/>
                    <TextField
                        id="outlined-multiline-static"
                        label="description"
                        name="description" 
                        multiline
                        fullWidth
                        rows={6}
                        defaultValue={editData.description}
                        onChange={this.changeHandler}
                        className='cl-text'
                        sx={{
                            '& > :not(style)': { m: 1 },
                        }}
                    />
                   <FormControl fullWidth sx={{
                        'margin-left': "8px",
                    }}>
                        <InputLabel id="demo-simple-select-label">Python Version</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="environment_version"
                        value={environment_version}
                        defaultValue={editData.environment_version}
                        label="Python Version"
                        onChange={this.changeHandler}
                        >
                        <MenuItem value="3.7.5">3.7.5</MenuItem>
                        </Select>
                    </FormControl>
                   <Typography variant="body2" component="div" sx={{ paddingTop:"10px"}}>
                        <span>Script：</span>
                        <input type="file"  onChange={this.fileChangeHandlerImage} placeholder=""/>
                   </Typography>
                    <br/>
                    <Button  sx={{marginTop:"30px"}}  variant="contained" onClick={this.onSubmitHandlerUpdate}>Submit</Button>
                    <br/>
                </form>
            </Box>
            </Modal>
            <Modal
            open={runModal}
            onClose={this.handleCloseRunModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={msgStyle}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                 <pre>{runMsg}</pre>  
                </Typography>
            </Box>
            </Modal>

            <Dialog
                open={delopen}
                onClose={this.handleCloseDel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">
             {"Tips"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Are you sure to delete this data?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={this.handleClickDelete}  sx={{color:"#1236ff"}}>confirm</Button>
            <Button onClick={this.handleCloseDel} autoFocus>
            cancel
            </Button>
            </DialogActions>
           </Dialog>
           
            <Dialog
                open={installOpen}
                onClose={this.handleCloseInstall}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">
                Pip install
            </DialogTitle>
            <DialogContent>
            <TextField label="Packages Name" fullWidth name="dependent" className='cl-text'  onChange={this.changeHandler}  variant="outlined" sx={{
                        '& > :not(style)': { m: 1 },
                    }}/>
            </DialogContent>
            <DialogActions>
            <Button onClick={this.handleClickInstall}  sx={{color:"#1236ff"}}>confirm</Button>
            <Button onClick={this.handleCloseInstall} autoFocus>
            cancel
            </Button>
            </DialogActions>
           </Dialog>

           <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={this.handleSnackbarClose} anchorOrigin={{ vertical, horizontal }}>
                <Alert onClose={this.handleSnackbarClose} severity={tip.status} sx={{ width: '100%' }}>
                     {tip.message}
                </Alert>
          </Snackbar>
            </HomeWrapper>
        )
    }
}

const mapState = (state) => ({
    opensea: state.getIn(['header', 'opensea']),
    chainId: state.getIn(['header', 'chainId']),
    account: state.getIn(['header', 'account']),
    myNftAssets: state.getIn(['header', 'myNftAssets']),
})


export default connect(mapState)(Profile);

